import React from 'react';
import { Link } from 'gatsby';

import { Icon, Text } from '@components';
import { Box, Button } from '@core';

const NavigationLink = ({ link, disabled, children, selected }) => {
  const LinkWrapper = disabled ? Box : ({ children }) => <Link to={link}>{children}</Link>;
  return (
    <LinkWrapper>
      <Box
        as="span"
        display="flex"
        justifyContent="center"
        minWidth={32}
        height={32}
        mx={{ _: 0, md: 8 }}
        backgroundColor={selected && 'primary'}
        borderRadius="50%"
      >
        <Button as="span" variant="text" disabled={disabled}>
          <Text.Body3 as="span" color={selected ? 'white' : 'darkBlue_60'}>
            {children}
          </Text.Body3>
        </Button>
      </Box>
    </LinkWrapper>
  );
};

const VISIBLE_PAGES = 5;

const getLink = (destinationPage, categoryLink) => {
  const categoryPrefix = categoryLink && categoryLink !== '/' ? categoryLink : '';
  return `/blog${categoryPrefix}/${!(destinationPage < 2) ? destinationPage : ''}`;
};

const ELLIPSIS = '...';

const BlogListNavigation = ({ currentPage, numberOfPages, categoryLink }) => {
  if (numberOfPages > 1) {
    const pages = Array.from(Array(numberOfPages)).map((_, i) => i + 1);

    if (pages.length > 6) {
      pages.splice(VISIBLE_PAGES - 1, pages.length - VISIBLE_PAGES, ELLIPSIS);
      if (!pages.includes(currentPage)) {
        pages.splice(
          VISIBLE_PAGES - 1,
          1,
          ...(numberOfPages / currentPage >= 2 ? [currentPage, ELLIPSIS] : [ELLIPSIS, currentPage])
        );
      }
    }
    return (
      <Box display="flex" justifyContent="center" mt={36} mb={24} height={32}>
        <NavigationLink disabled={currentPage === 1} link={getLink(currentPage - 1, categoryLink)}>
          <Box display="inline-flex" alignItems="center">
            <Icon.Left color="darkBlue_60" size={20} />
            <Box as="span" ml={{ _: 0, md: 16 }} mr={{ _: 8, md: 32 }}>
              Previous
            </Box>
          </Box>
        </NavigationLink>
        {pages.map((page) =>
          page === '...' ? (
            <Text.Body3 as="span" py={{ _: 3, md: 7 }} px={{ _: 6, md: 12 }} key={page}>
              {page}
            </Text.Body3>
          ) : (
            <NavigationLink
              key={page}
              selected={page === currentPage}
              link={getLink(page, categoryLink)}
            >
              {page}
            </NavigationLink>
          )
        )}
        <NavigationLink
          disabled={currentPage === numberOfPages}
          link={getLink(currentPage + 1, categoryLink)}
        >
          <Box display="inline-flex" alignItems="center">
            <Box as="span" ml={{ _: 8, md: 16 }} mr={{ _: 0, md: 32 }}>
              Next
            </Box>
            <Icon.Right color="darkBlue_60" size={20} />
          </Box>
        </NavigationLink>
      </Box>
    );
  } else {
    return null;
  }
};

export default BlogListNavigation;
