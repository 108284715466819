import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@core';
import { LinkWrapper, Text } from '@components';
import SignIn from '@images/thank-you-page/sign-in.svg';
import PickServer from '@images/thank-you-page/pick-server.svg';
import Browse from '@images/thank-you-page/browse-safely.svg';
import Protection from '@images/thank-you-page/protection.svg';
import Servers from '@images/thank-you-page/servers.svg';
import UnlimitedAccess from '@images/thank-you-page/unlimited-access.svg';
import NeedHelp from '@images/thank-you-page/need-help.png';
import AndroidIcon from '@images/devices/android-icon.svg';
import iOSIcon from '@images/devices/iOS-icon.svg';
import MacOSIcon from '@images/devices/macOS-icon.svg';
import WindowsIcon from '@images/devices/windows-icon.svg';
import AndroidTvIcon from '@images/devices/android-tv-icon.svg';
import FireTvIcon from '@images/devices/fire-tv-icon.svg';
import LinuxIcon from '@images/devices/linux-icon.svg';
import AppleTvIcon from '@images/platform-icons/apple-tv-black.svg';
import { APPS_LINKS } from '@shared/constants/apps';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { HELP_CENTER_LINK, SUPPORT_EMAIL } from '@shared/constants/constants';

const TextLinkWrapper = ({ children, link, target, rel }) => (
  <LinkWrapper link={link} target={target} rel={rel}>
    <Button type="button" variant="text">
      <Text.Body1 as="span" color="inherit">
        {children}
      </Text.Body1>
    </Button>
  </LinkWrapper>
);

export const PAGE_DATA = {
  compatibleDevices: (t) => ({
    title: (
      <React.Fragment>
        <Text.Body3Strong color="primary" mb={8} textAlign="center">
          {t('common:step', { stepNumber: '01' })}
        </Text.Body3Strong>
        {t('thankYou:compatibleDevices.title')}
      </React.Fragment>
    ),
    description: t('thankYou:compatibleDevices.description'),
    cards: [
      {
        image: WindowsIcon,
        title: t('common:platforms.windows'),
        button: {
          link: APPS_LINKS.windows,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.windows },
          },
        },
      },
      {
        image: MacOSIcon,
        title: t('common:platforms.macOS'),
        button: {
          link: APPS_LINKS.mac,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.macOS },
          },
        },
      },
      {
        image: LinuxIcon,
        title: t('common:platforms.linux'),
        button: {
          link: APPS_LINKS.linux,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.linux },
          },
        },
      },
      {
        image: AndroidIcon,
        title: t('common:platforms.android'),
        button: {
          link: APPS_LINKS.android,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.android },
          },
        },
      },
      {
        image: iOSIcon,
        title: t('common:platforms.iOS'),
        button: {
          link: APPS_LINKS.ios,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.iOS },
          },
        },
      },
      {
        image: AndroidTvIcon,
        title: t('common:platforms.androidTv'),
        button: {
          link: APPS_LINKS.android,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.androidTv },
          },
        },
      },
      {
        image: FireTvIcon,
        title: t('common:platforms.fireTv'),
        button: {
          link: APPS_LINKS.fireTv,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.fireTv },
          },
        },
      },
      {
        image: AppleTvIcon,
        title: t('common:platforms.appleTv'),
        button: {
          link: APPS_LINKS.appleTv,
          title: t('common:actions.download'),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.appleTv },
          },
        },
      },
    ],
    mobileCards: [
      {
        image: WindowsIcon,
        title: t('common:platforms.windows'),
        link: APPS_LINKS.windows,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.windows },
      },
      {
        image: MacOSIcon,
        title: t('common:platforms.macOS'),
        link: APPS_LINKS.mac,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.macOS },
      },
      {
        image: LinuxIcon,
        title: t('common:platforms.linux'),
        link: APPS_LINKS.linux,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.linux },
      },
      {
        image: AndroidIcon,
        title: t('common:platforms.android'),
        link: APPS_LINKS.android,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.android },
      },
      {
        image: iOSIcon,
        title: t('common:platforms.iOS'),
        link: APPS_LINKS.ios,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.iOS },
      },
      {
        image: AndroidTvIcon,
        title: t('common:platforms.androidTv'),
        link: APPS_LINKS.android,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.androidTv },
      },
      {
        image: FireTvIcon,
        title: t('common:platforms.fireTv'),
        link: APPS_LINKS.fireTv,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.fireTv },
      },
      {
        image: AppleTvIcon,
        title: t('common:platforms.appleTv'),
        link: APPS_LINKS.appleTv,
        showArrow: true,
        variant: 'secondary',
        customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.appleTv },
      },
    ],
  }),
  features: (t) => ({
    items: [
      {
        image: SignIn,
        step: 2,
        title: t('thankYou:stepCards.signIn.title'),
        description: t('thankYou:stepCards.signIn.description'),
      },
      {
        image: PickServer,
        step: 3,
        title: t('thankYou:stepCards.pickServer.title'),
        description: t('thankYou:stepCards.pickServer.description'),
      },
      {
        image: Browse,
        step: 4,
        title: t('thankYou:stepCards.browseSafely.title'),
        description: t('thankYou:stepCards.browseSafely.description'),
      },
    ],
  }),
  perks: (t) => ({
    title: t('thankYou:perkCards.title'),
    items: [
      {
        image: Protection,
        title: t('thankYou:perkCards.topProtection.title'),
      },
      {
        image: Servers,
        title: t('thankYou:perkCards.speedOptimizedServers.title', { serverCount: SERVER_COUNT }),
      },
      {
        image: UnlimitedAccess,
        title: t('thankYou:perkCards.unlimitedAccess.title'),
      },
    ],
  }),
  helpBlock: (t) => ({
    title: t('thankYou:contentBlocks.needHelp.title'),
    description: (
      <Trans
        i18nKey="thankYou:contentBlocks.needHelp.description"
        components={[
          <TextLinkWrapper key={0} link={HELP_CENTER_LINK} target="_blank" />,
          <TextLinkWrapper key={1} link={SUPPORT_EMAIL} />,
        ]}
      />
    ),
    image: NeedHelp,
  }),
  blogPostsSection: (t) => ({
    title: t('thankYou:moreTipsAndTricks'),
  }),
};
