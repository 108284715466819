import React from 'react';

import { Box } from '@core';
import { Text } from '@components';

const Header = ({ title, description }) => (
  <Box py={24}>
    <Text.Heading2 mb={24}>{title}</Text.Heading2>
    <Text.Body1>{description}</Text.Body1>
  </Box>
);

export default Header;
