import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Box, Grid } from '@core';
import { Text } from '@components';

const CardContainer = styled.div`
  border-radius: 8px;
  margin: 24px 0;
  overflow: hidden;
  height: 530px;
  background-color: ${({ theme }) => theme.colors.white_120};
  transition: 0.25s;
  color: ${({ theme }) => theme.colors.darkBlue};

  direction: ltr; // this card is used only in english

  &:hover {
    transform: translateY(-5px);
  }
`;

const TextContainer = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

const PostImage = styled(GatsbyImage)`
  min-height: 100%;
  width: 100%;
  object-fit: cover;
`;

const PostCard = ({ link, title, image, description, category, timeToRead }) => (
  // overrriding rtl plugin as this component is only in english
  <StyleSheetManager stylisPlugins={[]}>
    <Link to={link}>
      <CardContainer>
        <Box height="55%" overflow="hidden">
          <PostImage image={getImage(image)} alt={title} />
        </Box>
        <Grid.Container height="45%" justifyContent="space-between" px={24}>
          <Grid.Row>
            <Text.Subheading2 pt={32} pb={16}>
              <TextContainer>{title} </TextContainer>
            </Text.Subheading2>
            <Text.Body2>
              <TextContainer>{description}</TextContainer>
            </Text.Body2>
          </Grid.Row>
          <Grid.Row>
            <Box pb={24}>
              <Text.Body4Strong as="span">{category.toUpperCase()}</Text.Body4Strong>
              &nbsp;
              <Text.Body4 as="span">| {timeToRead} min read</Text.Body4>
            </Box>
          </Grid.Row>
        </Grid.Container>
      </CardContainer>
    </Link>
  </StyleSheetManager>
);

export default PostCard;
