import React from 'react';

import { Grid } from '@core';
import { BLOG_CATEGORIES } from '@shared/constants/blog';
import PostCard from './post-card';

const BlogPostsList = ({ posts, currentPage }) => (
  <Grid.Container>
    <Grid.Row justifyContent="space-between">
      {posts?.map(({ node: post }, index) => (
        <Grid.Col
          mx={{ md: index === 0 ? -8 : -16 }}
          px={{ md: index === 0 ? 8 : 16 }}
          key={index}
          size={{ _: 12, md: index === 0 && currentPage === 1 ? 8 : 4 }}
        >
          <PostCard
            link={`/blog${post.fields.slug}`}
            image={post.frontmatter.featuredimage}
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            category={
              BLOG_CATEGORIES.find((category) => category.id === post.frontmatter.category).name
            }
            timeToRead={post.timeToRead}
          />
        </Grid.Col>
      ))}
    </Grid.Row>
  </Grid.Container>
);

export default BlogPostsList;
