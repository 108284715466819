import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Box, Grid } from '@core';
import { Text } from '@components';

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  height: 1px;
  margin: 24px 0;
`;

const CategoriesWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

const CategoryItem = styled.div`
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.darkBlue : theme.colors.white};
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.darkBlue)};
  padding: 8px 24px;
  margin-right: 16px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  min-width: 120px;
  text-align: center;
`;

const Categories = ({ categories, selectedCategory }) => (
  <Box>
    <Divider />
    <CategoriesWrapper>
      <Grid.Container>
        <Grid.Row flexWrap="nowrap" overflowX="auto">
          {categories?.map((category) => (
            <Link to={`/blog${category?.link}`} key={category.name}>
              <CategoryItem selected={selectedCategory === category.link}>
                <Text.Body4 color="inherit">{category.name}</Text.Body4>
              </CategoryItem>
            </Link>
          ))}
        </Grid.Row>
      </Grid.Container>
    </CategoriesWrapper>
    <Divider />
  </Box>
);

export default Categories;
