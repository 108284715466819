import React from 'react';
import { graphql } from 'gatsby';
import styled, { StyleSheetManager } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import stylisRTLPlugin from 'stylis-plugin-rtl';

import { Card, ContentBlock, ImageCore, SectionContainerSC, Shape, Text } from '@components';
import { Box, Grid } from '@core';
import { CardsBlockSection, CheckoutNavBar, CompatibleDevices, Footer } from '@containers';
import { SocialLinks } from '@containers/footer/layouts/components';
import { PAGE_DATA } from '@shared/constants/pages/thank-you-page';
import checkMark from '@images/thank-you-page/check-mark.svg';
import { BlogPostsList } from '@templates/blog/components';

import { isLTR } from '@shared/translation-helpers';

const { compatibleDevices, features, perks, helpBlock, blogPostsSection } = PAGE_DATA;

const Wrapper = styled.div`
  direction: ltr;
`;

const SuccessPage = ({ data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const relatedPosts = data.allMarkdownRemark.edges;

  return (
    <StyleSheetManager stylisPlugins={isLTR(language) ? [] : [stylisRTLPlugin]}>
      <Wrapper id="success">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <CheckoutNavBar isLogoClickable />

        <Grid.Container
          data-section-id="ThankYouHeader"
          id="success-container"
          my={16}
          px={{ _: 16, xl: 0 }}
          maxWidth={1200}
          m="auto"
        >
          <Grid.Row>
            <Grid.Row alignItems="center" mb={8} data-testid="thank-you-title" columnGap={18}>
              <Box display={{ _: 'none', sm: 'block' }}>
                <ImageCore src={checkMark} alt="check" />
              </Box>
              <Text.Heading2
                id="success-title"
                $fontFamily="title"
                color={{ _: 'success', sm: 'fontDefault' }}
              >
                {t('thankYou:header.title')}
              </Text.Heading2>
            </Grid.Row>

            <Text.Body2 mt={8}>{t('thankYou:header.description')}</Text.Body2>
          </Grid.Row>
        </Grid.Container>
        <CompatibleDevices
          {...compatibleDevices(t)}
          sectionProps={{ wrapperProps: { my: { _: 24, md: 40 } } }}
        />
        <CardsBlockSection {...features(t)} itemComponent={Card.Feature} itemsOnDesktop={3} />

        <CardsBlockSection
          sectionProps={{ mt: { _: 40, md: 72 }, mb: { _: 20, md: 32 } }}
          {...perks(t)}
          itemComponent={Card.Feature}
          itemsOnDesktop={3}
        />
        <Box px={{ _: 16, lg: 24 }} maxWidth={1200} m="auto">
          <Box data-section-id="NeedHelp" mt={{ _: 40, md: 80 }} mb={{ _: 40, md: 120 }}>
            <ContentBlock {...helpBlock(t)}>
              <Grid.Row mt={16} columnGap={16}>
                <Text.Body1Strong mb={16}>{t('common:followUs') + ':'}</Text.Body1Strong>{' '}
                <Box>
                  <SocialLinks variant="light" />
                </Box>
              </Grid.Row>
            </ContentBlock>
          </Box>
          {!!relatedPosts.length && (
            <SectionContainerSC data-section-id="BlogPosts" {...blogPostsSection(t)}>
              <BlogPostsList posts={relatedPosts} />
            </SectionContainerSC>
          )}
        </Box>

        <Box position="relative" pt={48} mt={{ _: 40, md: 120 }} overflow="hidden">
          <Box position="absolute" top={0} zIndex={-1} width="200vw" left="-50vw">
            <Shape.Blob1 color="#3E4063" />
          </Box>
          <Footer />
        </Box>
      </Wrapper>
    </StyleSheetManager>
  );
};

export default SuccessPage;

export const relatedPostsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation", "thankYou"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "use case" } } }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            description
            featuredimage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            category
          }
          timeToRead
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
