import { formatLink } from '@shared/format-helpers';
import { SERVER_COUNT } from './server-locations';
import { GA4_EVENTS } from './google';

export const BLOG_CATEGORIES = [
  // id: 0 category MUST always be dedicated to all categories
  {
    id: 0,
    name: 'All Categories',
    link: '/',
    metaData: {
      title: 'Blog - Atlas VPN',
      description:
        'The ultimate source for staying up-to-date with all things related to security and data privacy. Get the latest scoop in our daily articles.',
    },
  },
  {
    id: 1,
    name: 'Research',
    link: '/reports',
    metaData: {
      title: 'Research - Atlas VPN',
      description:
        'Data-driven research articles on the latest cyber threats. Discover security insights, key findings, and prevention tactics.',
    },
  },
  {
    id: 2,
    name: 'Product Updates',
    link: '/product-updates',
    metaData: {
      title: 'Product Updates - Atlas VPN',
      description:
        'Grow together with Atlas VPN and be the first to learn about the newest features. Track the latest updates as we take our user experience to the next level.',
    },
  },
  {
    id: 3,
    name: 'Privacy',
    link: '/privacy',
    metaData: {
      title: 'Privacy - Atlas VPN',
      description:
        'All recent techniques of privacy preservation in one place. Learn how to protect your digital identity from prying eyes and mitigate risks.',
    },
  },
  {
    id: 4,
    name: 'How-To',
    link: '/how-to',
    metaData: {
      title: 'How-to - Atlas VPN',
      description:
        'Clear instructions and recommendations on how to tackle common obstacles, with a focus on protecting users’ data, and improving digital experiences.',
    },
  },
  {
    id: 5,
    name: 'Meet the Team',
    link: '/meet-the-team',
    metaData: {
      title: 'Meet the Team - Atlas VPN',
      description:
        'Read firsthand stories about Atlas VPN workplace and discover what makes it so special.',
    },
  },
];

export const PROFILES = [
  {
    id: 1,
    name: 'Edward G.',
    logo: '/assets/Edward.jpg',
    description:
      'Cybersecurity Researcher and Publisher at Atlas VPN. My mission is to scan the ever-evolving cybercrime landscape to inform the public about the latest threats.',
  },
  {
    id: 2,
    name: 'Anton P.',
    logo: '/assets/Anton.jpg',
    description:
      "Former chef and the head of Atlas VPN blog team. He's an experienced cybersecurity expert with a background of technical content writing.",
  },
  {
    id: 3,
    name: 'Ruth C.',
    logo: '/assets/Ruth.jpg',
    description:
      'Cybersecurity Researcher and Publisher at Atlas VPN. Interested in cybercrime, online security, and privacy-related topics.',
  },
  {
    id: 4,
    name: 'William S.',
    logo: '/assets/William.jpg',
    description:
      'Cybersecurity Researcher and Publisher at Atlas VPN. Focused on revealing the latest cybersecurity trends around the world.',
  },
];

export const BANNER_PRESETS = (t) => [
  {
    id: 'none',
    name: 'No Banner',
  },
  {
    id: 'generic',
    name: 'Generic',
    data: [
      {
        title: 'Get all benefits VPN can provide',
        description:
          'Experience the internet without limits — no geo-blocks, censorship, or tracking. Atlas VPN is your daily companion for a more open & secure internet!',
        image: '/assets/blog-post-banner-image.png',
        button: {
          title: 'Buy Atlas VPN',
          link: formatLink(t('navigation:paths.get')),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.blogBannerGenericClick },
          },
        },
      },
    ],
  },
  {
    id: 'security',
    name: 'Security',
    data: [
      {
        title: 'Browse safely & anonymously with a VPN',
        description:
          'Encrypt your internet traffic and defend against online snooping, hackers, governments, or ISPs.',
        image: '/assets/blog-post-banner-image.png',
        button: {
          title: 'Buy Atlas VPN',
          link: formatLink(t('navigation:paths.get')),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.blogBannerSecurityClick },
          },
        },
      },
    ],
  },
  {
    id: 'streaming',
    name: 'Streaming',
    data: [
      {
        title: 'Best VPN servers for streaming',
        description:
          'Stream your favorite movies and TV shows securely and privately, no matter where you are.',
        image: '/assets/blog-post-banner-image.png',
        button: {
          title: 'Buy Atlas VPN',
          link: formatLink(t('navigation:paths.get')),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.blogBannerStreamingClick },
          },
        },
      },
    ],
  },
  {
    id: 'speed',
    name: 'Speed',
    data: [
      {
        title: 'Ultra-fast VPN servers around the world',
        description: `Choose from ${SERVER_COUNT}+ high-speed VPN servers and enjoy fast and stable connections anywhere.`,
        image: '/assets/blog-post-banner-image.png',
        button: {
          title: 'Buy Atlas VPN',
          link: formatLink(t('navigation:paths.get')),
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.blogBannerSpeedClick },
          },
        },
      },
    ],
  },
];
